<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="8">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="link"/>  New Document API
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="10">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.name.$model"
                                    :isValid="checkIfValid($v.file_api, 'name')"
                                    label="Name" 
                                    type="text" 
                                    placeholder="JO Routing Document API" 
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-nospecialcharacter
                                    v-model="file_api.name"
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="10">
                                <label>Method</label>
                                <v-select 
                                    label="name"
                                    :options="methods_type"
                                    v-model="file_api.method"
                                    :class="!file_api.method ? 'has-error' : 'has-success'"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.api.$model"
                                    :isValid="checkIfValid($v.file_api, 'api')"
                                    label="URL" 
                                    type="text" 
                                    placeholder="https://test.po-routing.com.ph" 
                                    autocomplete="off"  
                                    v-model="file_api.api"
                                    invalidFeedback="Invalid URL"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CTextarea
                                    :lazy="false"
                                    :value.sync="$v.file_api.description.$model"
                                    :isValid="checkIfValid($v.file_api, 'description')"
                                    class="grp-text-area"
                                    label="Description"
                                    placeholder="Content..."
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="file_api.description"
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="link"/>  API Parameters
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="3">
                                <label>Name</label>
                            </CCol>
                            <CCol lg="3">
                                <label>Type</label>
                            </CCol>
                            <CCol lg="2">
                                <label>Reference number</label>
                            </CCol>
                            <CCol lg="2">
                                <label>Required</label>
                            </CCol>
                            <CCol lg="2"></CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="3">
                               <CInput
                                    type="text" 
                                    placeholder="JO Number" 
                                    autocomplete="off"  
                                    v-model="field.parameter"
                                    :class="!field.parameter ? 'has-inp-error' : 'has-inp-success'"
                                />
                            </CCol>
                            <CCol lg="3">
                                <v-select 
                                    label="name"
                                    :options="fields_type"
                                    v-model="field.type"
                                    :class="!field.type ? 'has-error' : 'has-success'"
                                />
                            </CCol>
                            <CCol lg="2">
                               <p-check 
                                    :disabled="file_api.document_template_file_api_params.some(function(item) { return item.is_reference})"
                                    class="p-icon p-bigger" 
                                    color="success" 
                                    value="true"
                                    v-model="field.reference_number"
                                >
                                    <font-awesome-icon class="icon" icon="check"/>
                                </p-check>  
                            </CCol>
                            <CCol lg="2">
                               <p-check 
                                    :disabled="field.reference_number ? true : false"
                                    class="p-icon p-bigger" 
                                    color="success" 
                                    value="true"
                                    v-model="field.required"
                                >
                                    <font-awesome-icon class="icon" icon="check"/>
                                </p-check>  
                            </CCol>
                            <CCol lg="2">
                                <CButton 
                                    :disabled="!field.parameter || !field.type"
                                    color="primary" 
                                    @click="addDynamicField()" >
                                    <font-awesome-icon icon="plus"/> Add Field
                                </CButton>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="file_api.document_template_file_api_params"
                                    :fields="parameters_field"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                >
                                    <template #parameter="{item}">
                                        <td>
                                            <CInput
                                                type="text" 
                                                autocomplete="off"  
                                                :value="item.parameter"
                                                v-model="item.parameter"
                                                :class="!item.parameter ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </td>
                                    </template>
                                    
                                    <template #type="{item}">
                                        <td >
                                            <v-select 
                                                label="name"
                                                :options="fields_type"
                                                v-model="item.type"
                                                :class="!item.type ? 'has-error' : 'has-success'"
                                            />
                                        </td>
                                    </template>
                                    <template #is_reference="{item}">
                                        <td >
                                            <!-- <CBadge :color="getColorStatus(item.is_reference ? 'Yes' : 'No')">
                                                {{item.is_reference ? 'Yes' : 'No'}}
                                            </CBadge> -->
                                            <p-radio 
                                                class="p-icon p-curve p-bigger" 
                                                name="icon_solid" 
                                                color="success"
                                                :value="item.id"
                                                @change="changeReference()"
                                                v-model="selected_reference">
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-radio> 
                                        </td>
                                    </template>
                                    <template #is_required="{item}">
                                        <td>
                                            <!-- <CBadge :color="getColorStatus(item.is_required ? 'Yes' : 'No')">
                                                {{item.is_required ? 'Yes' : 'No'}}
                                            </CBadge> -->
                                            <p-check 
                                                :disabled="item.is_reference ? true : false"
                                                class="p-icon p-bigger" 
                                                color="success" 
                                                value="true"
                                                v-model="item.is_required"
                                            >
                                                <font-awesome-icon class="icon" icon="check"/>
                                            </p-check>
                                        </td>
                                    </template>
                                    <!-- <template #action="{item}"> -->
                                    <template #action="{index}">
                                        <td>
                                            <CButton 
                                                @click="file_api.document_template_file_api_params.splice(index, 1)"
                                                color="danger" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter align="right">
                       <CButton 
                            :disabled="!isValid"
                            @click="isValid ? createDocumentFileAPI() : '' "
                            shape="pill" 
                            color="primary"
                        >
                            <font-awesome-icon icon="save"/> Save
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        
    </div>
</template>
<script>

import { required,minLength, url } from "vuelidate/lib/validators"
export default {
    name: 'DocumentAPICreate',
    data() {
        return {
            fields_type: [
                {name: "input"},
                {name: "textarea"}
            ],

            methods_type: [
                { name: 'GET' },
                { name: 'POST' },
            ],

            file_api: {
                name: null,
                method: null,
                api: null,
                description: null,
                document_template_file_api_params: [],
            },

            field: {
                parameter: null,
                type: null,
                reference_number: false,
                required: false,
            },

            parameters_field: [
                { key: 'parameter'},
                { key: 'type' },
                { key: 'is_reference', label: 'Reference Number' },
                { key: 'is_required', label: 'Required' },
                { key: 'action' },
            ],

            selected_reference: null,

        }
    },
    created() {
        this.$v.$touch()
        this.$emit('activeTab', 1);
    },
    computed: {
        isValid () { return !this.$v.file_api.$invalid },
        isDirty () { return this.$v.file_api.$anyDirty },
    },
    validations: {
        file_api: {
            name: { required, minLength: minLength(5) },
            method: { required, },
            api: { required, url },
            description: { required, minLength: minLength(5) },
            document_template_file_api_params: { required, minLength: minLength(1) }
        },
    },
    methods: {
        changeReference: function () {
            this.file_api.document_template_file_api_params = this.file_api.document_template_file_api_params.map(item=>{
                delete item.is_reference
                return item
            })

            for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                if(this.file_api.document_template_file_api_params[i].id == this.selected_reference) {
                    this.file_api.document_template_file_api_params[i].is_reference = true
                    this.file_api.document_template_file_api_params[i].is_required = true
                } else {
                    this.file_api.document_template_file_api_params[i].is_reference = false;
                    // this.file_api.document_template_file_api_params[i].is_required = false
                }
            }
        },
        addDynamicField: function() {
            if(!this.field.parameter || !this.field.type) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: 'Please fill up required fields.',
                    showConfirmButton: false,
                    timerProgressBar: true,
                }) 
            }

            let row = {
                id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                parameter: this.field.parameter,
                type: this.field.type.name,
                is_reference: this.field.reference_number,
                is_required: this.field.required,
            }

            for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                if(this.file_api.document_template_file_api_params[i].parameter == row.parameter && this.file_api.document_template_file_api_params[i].type == row.type) {
                    return this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'error',
                        title: `${row.parameter} already exists.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    }) 
                }
            }
            
            if(row.is_reference) {
                this.selected_reference = row.id
            }

            this.file_api.document_template_file_api_params.push(row)
            this.field = this.cleaFieldObject();
        },
        cleaFieldObject: function () {
            return {
                parameter: null,
                type: null,
                reference_number: false,
                required: false,
            }
        },
        clearFileAPIObject: function (){
            return {
                name: null,
                api: null,
                description: null,
                document_template_file_api_params: [],
            }
        },
        validateFileAPI:function () {
            if( !this.file_api.name || !this.file_api.method || !this.file_api.api || !this.file_api.description || this.file_api.document_template_file_api_params.length <= 0 ){
                return false;
            } 
            return true;
        },
        createDocumentFileAPI: function (){
            if(!this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference })) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 3000,
                    icon: 'error',
                    title: `Please select reference number from the parameters.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }

            if(this.validateFileAPI()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new Document File API.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 

                        
                        this.file_api.method = this.file_api.method.name

                        for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                            const element = this.file_api.document_template_file_api_params[i];
                            element.id = null
                        }


                        for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                            const element = this.file_api.document_template_file_api_params[i];
                            if(element.type.name) {
                                let selected_type = element.type.name
                                element.type = selected_type
                            }
                        }

                        this.$Progress.start()
                        return axios.post('/drs/document-template-file-api/store', this.file_api, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.file_api.name} has been added.`,
                                })
                                this.file_api = this.clearFileAPIObject();
                                // this.users_shared_routing_list = []
                                
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish()
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            }) 
            return;
        }
    },
    watch: {
        'field.reference_number': function (value) {
            this.field.required = value ? true : false 
        }
    }
}
</script>
